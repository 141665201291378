import React, {  useEffect, useState } from "react"
import { Link } from "gatsby"
import Badge from "./Badge"

const Card = ({ data, small, noModal, medium, oneProduct }) => {
  const name = data?.name ?? ""
  const image = Array.isArray(data?.image) ? data?.image[0] : data?.image
  const slug = data?.slug ?? "#"
  const type = data?.type ?? undefined
  const productType = data?.productType ?? undefined
  const urlpath = type || productType
  // console.log(data)


  const badgeSource = <Badge data={data} />
  const [badge, setBadge] = useState()
  useEffect(() => {
    setBadge(badgeSource)
  }, [])


  return (
    <>
      <Link
        to={`/${urlpath}/${slug}`}
        className="relative flex flex-col rounded shadow cursor-pointer sm:max-w-xs group hover:shadow-lg"
      >
        <div
          className={`relative flex object-cover overflow-hidden rounded group-hover:rounded-b-none ${
            small ? "h-20" : medium ? "h-40 max-w-40" : oneProduct? "h-56" : "h-40 md:h-56"
          }`}
          style={{
            WebkitBackfaceVisibility: "hidden",
            MozBackfaceVisibility: "hidden",
            WebkitTransform: "translate3d(0, 0, 0)",
            MozTransform: "translate3d(0, 0, 0)",
          }}
        >
          {image}
          <div className="absolute inset-0 w-full h-full">&nbsp;</div>
          <div className="absolute inset-0 hidden transition bg-brand-dark opacity-10 group-hover:flex"></div>
          {badge}
        </div>
        <span
          className={`py-2  text-center text-gray-700 rounded-b group-hover:text-gray-900 text-xs ${
            small ? "font-normal" : "font-bold uppercase"
          }`}
        >
          {name}
        </span>
      </Link>
    </>
  )
}

export default Card
