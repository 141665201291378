import React from "react"
import { GeneralDescText } from "../graphql/QueryById"
import TitleChimeg from "../components/TitleChimeg"

const GeneralDescription = ({ desc, sectionName }) => {
  return (
    <div
      className="relative z-20 flex flex-col items-center justify-center max-w-screen-lg px-2 py-4 my-8 bg-white md:p-10 md:mx-4 lg:mx-auto "
      style={{
        WebkitBackfaceVisibility: "hidden",
        MozBackfaceVisibility: "hidden",
        WebkitTransform: "translate3d(0, 0, 0)",
        MozTransform: "translate3d(0, 0, 0)",
      }}
    >
      <h2 className="px-6 pt-2 text-sm antialiased font-semibold text-center uppercase sm:pt-4 md:text-base text-brand">
        {sectionName + " " + GeneralDescText()}
      </h2>
      <span className="">
        <TitleChimeg />
      </span>
      {desc}
    </div>
  )
}

export default GeneralDescription
