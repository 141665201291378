import React from "react"

const PageTitle = ({ title, text }) => {
  return (
    <div className="text-brand">
      <div className="relative z-10 flex justify-center px-10 my-20 text-xl font-semibold text-center uppercase md:text-2xl md:text-left md:px-0">
        {title}
      </div>
      {text && (
        <div className="max-w-screen-sm mx-2 text-center md:mx-auto">
          * {text}
        </div>
      )}
    </div>
  )
}

export default PageTitle
