import React from "react"
import titleBelow from "../images/titleBelow.svg"

const TitleChimeg = () => {
  return (
    <img
      src={titleBelow}
      alt="Title chimeg"
      className="w-24 py-4 text-brand opacity-80"
    />
  )
}

export default TitleChimeg
