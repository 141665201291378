import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { HivsentserDesc } from "../graphql/HivsentserSmallQueries"
import PageHeader from "./PageHeader"
import titleBelow from "../images/titleBelow.svg"
import { AllHivsentser } from "../graphql/AllHivsentserQuery"
import { useTranslation } from "react-i18next"
import Card from "../components/Card"
import { GeneralDescText } from "../graphql/QueryById"
import GeneralDescription from "../components/GeneralDescription"
import PageTitle from "../components/PageTitle"
import SEO from "../components/seo"

const HivsentserMainPageTemplate = () => {
  const headerImage = HivsentserDesc()[0].image ?? undefined
  const headerTitle = HivsentserDesc()[0].name ?? undefined
  const md = HivsentserDesc()[0].md ?? undefined
  const mdIsEmpty = md.props.children.props.children.trim().length !== 0
  const mdContent = md.props.children.props.children
  // console.log(mdIsEmpty)

  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const allHivsentser = AllHivsentser() ?? undefined
  const totalProduct = allHivsentser.length
  const [componentData, setComponentData] = useState(allHivsentser)
  useEffect(() => {
    setComponentData(allHivsentser)
  }, [currentLanguage])

  const titleChimeg = (
    <img
      src={titleBelow}
      alt="Title chimeg"
      className="w-24 py-4 text-gray-400 opacity-80"
    />
  )

  const oneProduct = componentData.length

  const cards = componentData.map((item, index) => {
    return <Card key={index} data={item} oneProduct={oneProduct} />
  })

  const totalCards = cards.length

  return (
    <Layout>
      <SEO title={headerTitle} />
      {/* <PageHeader image={headerImage} title={headerTitle} tall /> */}
      <PageTitle title={headerTitle} />
      <div className="max-w-screen-lg mx-auto my-10">
        {mdIsEmpty && (
          <GeneralDescription desc={md} sectionName={headerTitle} />
        )}
        <div
          className={`grid gap-4 mx-2 mt-10 md:grid-cols-4 sm:grid-cols-3 md:mx-4 lg:mx-0 md:bg-white  relative z-10 md:p-10 ${
            totalCards === 1 ? "grid-cols-1" : "grid-cols-2"
          }`}
        >
          {cards}
        </div>
      </div>
    </Layout>
  )
}

export default HivsentserMainPageTemplate
