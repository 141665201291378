import React from "react"
import {
  BadgeNew,
  BadgeHot,
  BadgeBestseller,
  BadgeSale,
} from "../graphql/SmallQueries"

const Badge = ({ data }) => {
  // console.log(data)
  const badges = (
    <>
      {data.newProduct && (
        <div className="absolute opacity-70 -top-1.75 -left-1.75">
          <span className="flex w-12 h-12">{BadgeNew()[0].icon}</span>
        </div>
      )}
      <div className="absolute inset-x-0 flex justify-end top-1 right-1">
        {data.hot && (
          <div className=" opacity-80">
            <span className="flex w-8 h-8">{BadgeHot()[0].icon}</span>
          </div>
        )}
        {data.sale && (
          <div className=" opacity-80">
            <div className="relative">
              <span className="flex w-10 h-8">{BadgeSale()[0].icon}</span>
              <span className="absolute text-xs text-white inset-2 left-2.5">
                {data.sale}
              </span>
            </div>
          </div>
        )}
        {data.best && (
          <div className=" opacity-80">
            <div className="relative">
              <span className="flex w-9 h-9">
                {BadgeBestseller()[0].icon}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
  return badges
}

export default Badge
