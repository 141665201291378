import React from "react"

const PageHeader = ({ title, text, image, tall }) => {
  return (
    <div
      className={`relative flex flex-col items-center justify-center mx-auto bg-gray-100 ${
        tall ? "md:h-96 h-56" : "h-40"
      } ${image ? "max-w-screen-xl" : ""}`}
    >
      {image && (
        <div className="absolute inset-0 flex object-cover w-full h-full mx-auto overflow-hidden">
          {image}
          {/* <div className="absolute inset-0 bg-white bg-opacity-10"></div> */}
          {/* <div className="absolute inset-0 opacity-50 bg-gradient-to-r from-gray-100 via-transparent to-gray-100"></div> */}
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>
      )}
      {title && (
        <h2
          className={`z-10 inline max-w-lg px-2 text-center rounded  decoration-clone uppercase  ${
            image
              ? "text-white font-extrabold uppercase  tracking-widest  md:text-4xl text-lg"
              : "font-semibold text-gray-800 text-xl md:text-2xl"
          }`}
        >
          {title}
        </h2>
      )}

      {text && (
        <div
          className={`z-10 inline max-w-sm px-2 mt-2 text-center text-sm md:text-base  md:max-w-lg  ${
            image
              ? "text-white font-semibold bg-black bg-opacity-10"
              : "text-gray-500"
          }`}
        >
          {text}
        </div>
      )}
    </div>
  )
}

export default PageHeader
